import React from 'react'
import { navigate } from 'gatsby-link'
import Layout from '../../components/Layout'
import SEO from '../../components/Seo'
import Sentry from 'gatsby-plugin-sentry'

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

export default class Index extends React.Component {
  constructor(props) {
    super(props)
    this.state = { isValidated: false }
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleSubmit = e => {
    const form = e.target
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...this.state,
      }),
    })
      .then(() => navigate(form.getAttribute('action')))
      .catch(error => {
        Sentry.captureException(error)
        alert(error)
      })
  }

  render() {
    return (
      <>
        <div className='full-width-image-container margin-top-0'>
          <h2 className='has-text-weight-bold is-size-1'>Contact</h2>
        </div>

        <section className='section'>
          <div className='container'>
            <div className='grid has-text-centered'>
              <div className='cell'>
                <article className='is-child has-background-transparent'>
                  <div className='content is-multiline is-mobile'>
                    <h1 className='has-text-white'>Get in touch</h1>
                    <form
                      name='ContactUsForm'
                      method='post'
                      action='/contact/thanks/'
                      data-netlify='true'
                      data-netlify-honeypot='bot-field'
                      onSubmit={this.handleSubmit}
                    >
                      <input type='hidden' name='form-name' value='ContactUsForm' />
                      <div hidden>
                        <label>
                          Don’t fill this out: <input name='bot-field' onChange={this.handleChange} />
                        </label>
                      </div>
                      <div className='field'>
                        <label className='label has-text-white' htmlFor='name' required>
                          Name
                        </label>
                        <div className='control'>
                          <input
                            className='input'
                            type='text'
                            name='name'
                            onChange={this.handleChange}
                            id='name'
                            required={true}
                            placeholder={'e.g Mihir Pipermitwala'}
                          />
                        </div>
                      </div>
                      <div className='field'>
                        <label className='label has-text-white' htmlFor='email' required>
                          Email
                        </label>
                        <div className='control'>
                          <input
                            className='input'
                            type='email'
                            name='email'
                            onChange={this.handleChange}
                            id='email'
                            required={true}
                            placeholder={'e.g. mihirpipermitwala@gmail.com'}
                          />
                        </div>
                      </div>
                      <div className='field'>
                        <label className='label has-text-white' htmlFor='mobile' required>
                          Mobile Number
                        </label>
                        <div className='control'>
                          <input
                            className='input'
                            type='mobile'
                            name='mobile'
                            onChange={this.handleChange}
                            id='mobile'
                            required={true}
                            placeholder={'e.g 123-456-7890'}
                          />
                        </div>
                      </div>

                      <div className='field'>
                        <label className='label has-text-white' htmlFor='subject'>
                          Subject
                        </label>
                        <div className='control'>
                          <textarea
                            className='input'
                            type='text'
                            name='subject'
                            onChange={this.handleChange}
                            id='subject'
                            required={false}
                            placeholder={'e.g Subject'}
                          />
                        </div>
                      </div>
                      <div className='field'>
                        <label className='label has-text-white' htmlFor='message' required>
                          Message<span></span>
                        </label>
                        <div className='control'>
                          <textarea
                            className='textarea'
                            name='message'
                            onChange={this.handleChange}
                            id='message'
                            required={true}
                            placeholder={'e.g Message'}
                          />
                        </div>
                      </div>
                      <div className='field'>
                        <div className='control'>
                          <button
                            className='button is-success is-medium is-fullwidth has-background-dark'
                            type='submit'
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </article>
              </div>
            </div>
          </div>
        </section>
      </>
    )
  }
}
